import React, { useCallback } from "react";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { widthBreakpoints } from "../../assets/theme";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { toggleSidebar } from "../../store/sidebarStore";
import SidebarItem, {
  SidebarItemProps as SidebarItemProps,
} from "./SidebarItem";
import { getUser, useLogin } from "../../hooks/auth";
import useSidebarStyles from "./style";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";
import { storeUserData } from "../../store/userStore";
import { AxiosResponse } from "axios";
import { UserDataWOToken } from "../../api/credentialsAPI";
import { showProfileImage } from "../../api/profileAPI";

interface Props {
  sidebarItems: SidebarItemProps[];
}

const StyledNavigationItemWrapper = styled.div`
  flex: 1;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border: 3px solid #1c1c24;
  }
  overflow-y: auto;
`;

const Sidebar: React.FunctionComponent<Props> = ({ sidebarItems: nav }) => {
  const classes = useSidebarStyles();
  const userData = useAppSelector(({ userStore }) => {
    return userStore.userData;
  });
  const expand = useAppSelector(
    ({ sidebarStore }) => sidebarStore.sidebarExpand,
  );
  const dispatch = useAppDispatch();

  const dimension = useWindowDimensions();

  const login = useLogin();

  const isGreaterThan768 = useMediaQuery("(min-width: 768px)");

  const showProfileImageCallBack = useCallback(
    async (userData: UserDataWOToken) => {
      await showProfileImage().then((res: AxiosResponse) => {
        let image = btoa(
          new Uint8Array(res.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            "",
          ),
        );
        // console.log(res);
        dispatch(
          storeUserData({
            ...userData,
            profileImage: `data:${res.headers[
              "content-type"
            ].toLowerCase()};base64,${image}`,
          }),
        );
      });
    },
    [],
  );

  React.useEffect(() => {
    showProfileImageCallBack(userData as UserDataWOToken);
  }, []);

  React.useEffect(() => {
    if (isGreaterThan768) {
      dispatch(toggleSidebar(dimension.width > widthBreakpoints[2]));
    }
    // eslint-disable-next-line
  }, [isGreaterThan768]);

  React.useEffect(() => {
    if (!userData) {
      login().then(({ id, ...restLoginRes }) =>
        getUser({ id: id }).then(({ ...restUser }) => {
          let dataUser = restUser.data.data[0];
          dispatch(storeUserData({ ...dataUser, ...restLoginRes }));
          showProfileImageCallBack({ ...dataUser, ...restLoginRes });
        }),
      );
    }
  }, [userData, login]);

  return (
    <div className={[classes.container, expand ? "expand" : ""].join(" ")}>
      {/* <div
        className={[classes.header, expand ? "expand" : "shorten"].join(" ")}
      >
        <img
          src={expand ? rapidsense_by_datacakra : rapidsense_notext}
          alt="logo"
          onClick={() => navigate(getDefaultRoute(userData?.usertypeName))}
          style={{ transform: expand ? "scale(0.75)" : "", cursor: "pointer" }}
        />
      </div> */}
      {/* Logo Container */}
      <div
        className={[classes.logoContainer, expand ? "expand" : ""].join(" ")}
      >
        <div
          className={[classes.sidebarLogoWrapper, expand ? "expand" : ""].join(
            " ",
          )}
        >
          {/* <img
            src={puspenerballogo}
            style={{ width: expand ? 120 : 40 }}
            alt="puspenerballogo"
          /> */}
          {/* <img
            alt="profile"
            src={userData?.profileImage ? userData?.profileImage : noImage_png}
            onError={(e) => (e.currentTarget.src = noImage_png)}
            className={[classes.sideBarLogo, expand ? "expand" : ""].join(" ")}
          /> */}
        </div>
        {/* {expand && (
          <>
            <span
              style={{
                color: "white",
                fontSize: "1.25rem",
                fontWeight: 700,
                letterSpacing: "0.025rem",
              }}
            >
              {userData?.username}
            </span>
            <span
              style={{
                color: theme.gray,
                fontSize: "1rem",
              }}
            >
              {userData?.companyName}
            </span>
            <span style={{ color: theme.gray, fontSize: "1rem" }}>
              {userData?.usertypeName}
            </span>
          </>
        )} */}
      </div>
      {/* Navigations Container */}
      <StyledNavigationItemWrapper>
        {nav.map((props) => {
          return <SidebarItem key={props.title} {...props} />;
        })}
      </StyledNavigationItemWrapper>
      <div
        className={[classes.footer, expand ? "expand" : ""].join(" ")}
        onClick={() => dispatch(toggleSidebar())}
      >
        {expand ? <BsChevronLeft /> : <BsChevronRight />}
      </div>
    </div>
  );
};

export default Sidebar;
