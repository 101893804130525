import React from "react";
import { useLocation } from "react-router-dom";
import { KiddoElement } from "../components/Sidebar/SidebarItem";

interface Props {
  kiddos?: KiddoElement[];
  parentURL?: string;
}

interface ReturnItem {
  /**
   * if the NavbarItem match, either the parent or the child
   */
  match: boolean;
  /**
   * if the NavbarItem a parent, this will show which kiddo is matched.
   * if there is no kiddos, this will be empty string
   */
  matchKiddo: string;
}

//@ts-ignore
const defaultVal: ReturnItem = {
  match: false,
  matchKiddo: "",
};

const useMatchKiddo = ({ kiddos, parentURL }: Props): ReturnItem => {
  const { pathname } = useLocation();
  // const sisfoParentUrl = useGetSisfoParentUrl();
  // const [state, setState] = React.useState<ReturnItem>({match: false, matchKiddo: ""});

  return React.useMemo<ReturnItem>(() => {
    if (kiddos) {
      const found = kiddos.find((kid) => {
        return kid.kiddoURL ? pathname.indexOf(kid.kiddoURL) === 0 : false;
      });

      return {
        match: !!found,
        matchKiddo: found?.kiddoURL ?? "",
      };
    } else {
      return {
        match: parentURL ? pathname.indexOf(parentURL) === 0 : false, //
        matchKiddo: "",
      };
    }
  }, [kiddos, parentURL, pathname]);

  // return state
};

export default useMatchKiddo;
