import { createUseStyles } from "react-jss";
import styled from "styled-components";
import { xSmall } from "../../assets/breakpoints";
import { BasicTheme, COLORS } from "../../assets/theme";
import { hexToRgba } from "../../assets/usefulFunctions";

export const SidebarNavWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  background: ${COLORS.white};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.25);
  top: 0;
  left: ${(props) => (props.isOpen ? "240px" : "0px")};
  min-height: 100vh;
  transform: translateX(-100%);
  width: 240px;
  transition: left 0.3s ease-in-out;
  z-index: 10000;
`;

export const SidebarNavItem = styled.li<{ isActive: boolean }>`
  list-style-type: none;
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
  color: ${(props) => (props.isActive ? COLORS.white : COLORS.black_1)};
  font-weight: ${(props) => (props.isActive ? 600 : 400)};
  background: ${(props) => (props.isActive ? COLORS.blue_1 : "transparent")};
  :hover {
    background: ${(props) =>
      props.isActive ? COLORS.blue_1 : hexToRgba(COLORS.light_grey_2, 0.15)};
    color: ${(props) => (props.isActive ? COLORS.white : COLORS.black_1)};
  }
`;

export const SidebarNavBackdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: ${(props) => (props.isOpen ? "100vh" : "0vh")};
  background: ${(props) =>
    props.isOpen ? "rgba(0, 0, 0, 0.5)" : "transparent"};
  transition: background 0.3s ease-in-out;
  z-index: 9999;
`;

const useSidebarStyles = createUseStyles((theme: BasicTheme) => {
  return {
    container: {
      width: "50px",
      // background: "rgba(123, 123, 123, 0.15)",
      background: COLORS.black_1,
      borderRight: "1px solid #7B7B7B",
      backdropFilter: "blur(5px)",
      display: "flex",
      flexDirection: "column",
      minHeight: 600,
      maxHeight: "100vh",
      // transition: "width 250ms ease-in-out",
      transition: "width .3s ease",
      "&.expand": {
        width: "200px",
      },
      [xSmall]: {
        "&.expand": {
          width: "100%",
        },
      },
    },
    header: {
      background: "rgba(123, 123, 123, 0.15)",
      backdropFilter: "blur(5px)",
      // backgroundColor: COLORS.black_2,
      flexBasis: theme.barheight,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      transition: "all .2s",
      "&.expand": {
        padding: "1.25rem 0",
      },
      "&.shorten": {
        padding: "1.25rem 0",
      },
      "& > img": {
        height: `calc(${theme.barheight} - 5px)`,
      },
      "&.shorten > img": {
        width: "25px",
      },
    },
    logoContainer: {
      color: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "capitalize",
      padding: "4rem 0",
      transition: "width .3s ease",
      gap: 4,
    },
    logoutButton: {
      border: 0,
      borderRadius: "1rem 0 0 1rem",
    },
    navItem: {
      paddingLeft: "1rem",
      "&:not(:first-child)": {
        marginTop: "15px",
      },
      "&:not(:last-child)": {
        marginBottom: "15px",
      },
      "&> button": {
        padding: ".5rem",
        borderRadius: "1rem 0 0 1rem",
      },
    },
    logoutContainer: {
      flexBasis: theme.barheight,
      display: "flex",
      flexDirection: "column",
      marginLeft: "1rem",
    },
    footer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: COLORS.white,
      paddingTop: "15px",
      paddingBottom: "15px",
      // backgroundColor: COLORS.black_2,
      cursor: "pointer",
      fontWeight: "600",

      "&:hover": {
        opacity: 0.95,
      },
    },
    sidebarLogoWrapper: {
      marginBottom: "8px",
    },
    sideBarLogo: {
      transition: "all .2s",
      width: "36px",
      height: "36px",
      objectFit: "cover",
      borderRadius: "50%",
      "&.expand": {
        width: "52px",
        height: "52px",
      },
    },
  };
});

export default useSidebarStyles;
