import { add } from "date-fns";
import { UserData, UserDataWOToken } from "../api/credentialsAPI";

export const MockUserDataWOToken: UserDataWOToken = {
  id: 54,
  firstname: "Dartono 1",
  lastname: "Saptono",
  username: "dartonosaptono",
  email: "testingakun@gmail.com",
  usertypeId: 3,
  companyId: 24,
  phonenumber: "3242351322435",
  lastseennotification: "2022-09-21T08:11:52.578Z",
  companyName: "PT Papero Utama",
  locationId: 1,
  usertypeName: "admin",
};

export const MockUserData: UserData = {
  firstname: "Dartono ",
  lastname: "Saptono",
  username: "dartonosaptono",
  email: "dartono.saptono@gmail.com",
  id: 173,
  companyId: 1,
  profileImage: "1",
  jwt: "test",
  usertypeId: 1,
  usertypeName: "admin",
  companyName: "PT DataCakra",
  locationId: 1,
  phonenumber: "324235",
  lastseennotification: "2022-07-04T15:29:59.044Z",
  iat: add(new Date(), { hours: 0 }).getTime(),
  exp: add(new Date(), { hours: 2 }).getTime(),
};
