import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import userStore from "./userStore";
import sidebarStore from "./sidebarStore";
import { userStoreAPI } from "./userStore/userStoreAPI";
import { sensorlogPocWaterlevelDashboardStoreAPI } from "./dashboard/sensorlogPocWaterlevelDashboardStoreAPI";
import { waterlevelDashboardStoreAPI } from "./dashboard/waterlevelDashboardStore";

export const store = configureStore({
  /**
   * @todo sort these reducer slices by alphabet, ascending.
   */
  reducer: {
    userStore,
    [sensorlogPocWaterlevelDashboardStoreAPI.reducerPath]: sensorlogPocWaterlevelDashboardStoreAPI.reducer,
    [waterlevelDashboardStoreAPI.reducerPath]: waterlevelDashboardStoreAPI.reducer,
    sidebarStore,
    [userStoreAPI.reducerPath]: userStoreAPI.reducer,
  },
  middleware: (getMiddleWares) => {
    /**
     * @todo sort these middlewares by alphabet, ascending.
     */
    let middlewares = getMiddleWares().concat([
      userStoreAPI.middleware,
      waterlevelDashboardStoreAPI.middleware,
      sensorlogPocWaterlevelDashboardStoreAPI.middleware
    ]);
    return middlewares;
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
