import React from "react";
import { Toaster } from "react-hot-toast";
import { HashRouter, Route, Routes, Outlet } from "react-router-dom";
import LoaderPage from "./components/atoms/Loader/LoaderPage/LoaderPage";
import AuthHOC from "./components/auth/AuthHOC";
import { SidebarItemProps } from "./components/Sidebar/SidebarItem";
import { ListOfRoutes, NECRoutes } from "./config/nav";
import Layout from "./layout/Layout";
import NoMatch from "./pages/NoMatch";
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const HomePage = React.lazy(() => import("./pages/HomePage/HomePage"));
const ReportPage = React.lazy(() => import("./pages/ReportPage"));

const bsdRoutes = new NECRoutes();

/**
 * @todo each portal should have its own nav
 */
export const basicNav: SidebarItemProps[] = [
  {
    parentURL: bsdRoutes.home,
    title: "Dashboard",
  },
  {
    parentURL: bsdRoutes.report,
    title: "Report",
  },
];

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route
            path={`${bsdRoutes.home}`}
            element={
              <AuthHOC>
                <Outlet />
              </AuthHOC>
            }
          >
            <Route
              index
              element={
                <Layout showSidebar={false} sidebarItems={basicNav}>
                  <HomePage />
                </Layout>
              }
            />
            <Route
              path={`${bsdRoutes.report}`}
              element={
                <Layout showSidebar={false} sidebarItems={basicNav}>
                  <ReportPage />
                </Layout>
              }
            />
          </Route>
          <Route
            path={ListOfRoutes.loginpage}
            element={
              <React.Suspense fallback={<LoaderPage />}>
                <LoginPage />
              </React.Suspense>
            }
          />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </HashRouter>
      <Toaster />
    </>
  );
}

export default App;
