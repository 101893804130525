import MockAdapter from "axios-mock-adapter";
import { MockUserData, MockUserDataWOToken } from ".";
import { BackendDataShape, CredsLinks } from "../api/config";
import { UserData, UserDataWOToken } from "../api/credentialsAPI";
import axiosMockInstance from "./AxiosMockAdapter";

const mock = new MockAdapter(axiosMockInstance, { delayResponse: 100 });

mock
  .onGet(`${CredsLinks.user("")}`)
  .reply<BackendDataShape<UserDataWOToken[]>>(200, {
    status: "success",
    data: [MockUserDataWOToken],
  });

mock.onPost(`${CredsLinks.login}`).reply<BackendDataShape<UserData>>(200, {
  status: "success",
  data: MockUserData,
});
