import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BackendDataShape, UsedAPI } from "../../../api/config";
import CookieMonster from "../../../api/CookieMonster";
import { convertToEncodedURL } from "../../../assets/usefulFunctions";
import { ADeviceWithSubAndSummary } from "../../../types/endpoints/device";
import { APOCWaterLevelReport, APOCWaterLevelReportFilterReqObj, APOCWaterlevelSensorLogSinarmasReqObject } from "../../../types/endpoints/waterlevel";

export const sensorlogPocWaterlevelDashboardStoreAPI = createApi({
  reducerPath: "sensorDashboardStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/dashboard/sensorlog`,
    prepareHeaders: (headers) => {
      let token = CookieMonster.loadCookie("jwt");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["WaterlevelPocSinarmasSensorlogList", "WaterlevelPocReportList"],
  endpoints: (builder) => {
    return {
      getPocWaterlevelSensorLogDashboard: builder.query<
        ADeviceWithSubAndSummary[],
        APOCWaterlevelSensorLogSinarmasReqObject
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          const url = "/waterlevel/poc" + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["WaterlevelPocSinarmasSensorlogList"],
        transformResponse: (
          response: BackendDataShape<ADeviceWithSubAndSummary[]>,
        ) => {
          return response.data;
        },
      }),
      getWaterLevelPocReport: builder.query<
        APOCWaterLevelReport[],
        APOCWaterLevelReportFilterReqObj
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          const url = "/waterlevel/poc/report" + params;
          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["WaterlevelPocReportList"],
        transformResponse: (
          response: BackendDataShape<APOCWaterLevelReport[]>,
        ) => {
          return response.data;
        },
      }),
    };
  },
});

export const {
  useGetPocWaterlevelSensorLogDashboardQuery,
  useGetWaterLevelPocReportQuery,
  util: { resetApiState: resetSensorlogPocWaterlevelDashboardStoreAPI },
} = sensorlogPocWaterlevelDashboardStoreAPI;
