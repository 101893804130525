import React, { Suspense } from "react";
import { createUseStyles } from "react-jss";
import { BasicTheme, COLORS } from "../assets/theme";
import LoaderPage from "../components/atoms/Loader/LoaderPage/LoaderPage";
import Sidebar from "../components/Sidebar/Sidebar";
import { SidebarItemProps } from "../components/Sidebar/SidebarItem";

interface Props {
  sidebarItems: SidebarItemProps[];
  /**
   * if `true`, no sidebar will be shown
   */
  showSidebar?: boolean;
}

const useStyles = createUseStyles((_: BasicTheme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "row",
      minHeight: "100vh",
      backgroundColor: COLORS.light_grey_1,
      // backgroundImage: `url(${puspenerbalBackgroundMapImage})`,
      // backgroundSize: "cover",
      // backgroundPosition: "center",
      // backgroundRepeat: "no-repeat",
    },
    sidebar: {
      flexBasis: "230px",
      maxHeight: "100vh",
      display: "flex",
    },
    outlet: {
      flex: 1,
      // maxHeight: "100vh",
      overflowY: "auto",
    },
  };
});

const Layout: React.FunctionComponent<Props> = ({
  children,
  showSidebar = true,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {showSidebar && <Sidebar sidebarItems={[]} />}
      <div className={classes.outlet}>
        {/**
         * @todo add new loading fallback here
         */}
        <Suspense fallback={<LoaderPage />}>{children}</Suspense>
      </div>
    </div>
  );
};

export default Layout;
