import React from "react";
import { Navigate } from "react-router-dom";
import { KnownUsertype } from "../api/credentialsAPI/usertype";
import useUsertype from "../hooks/auth/useUsertype";

interface Props {}

const Redirector: React.FunctionComponent<Props> = (_: Props) => {
  const to = useDefaultRoute();
  return <Navigate replace to={to} />;
};

export default Redirector;

export const getDefaultRoute = (usertype?: KnownUsertype) => {
  switch (usertype) {
    case "operator":
    case "superadmin":
    case "officer":
    case "admin":
    default:
      return "/";
    /**
     * @todo butuh penyesuaian default route untuk semua user
     */
  }
};

export const useDefaultRoute = () => {
  const usertype = useUsertype();
  return React.useMemo(() => {
    return getDefaultRoute(usertype);
  }, [usertype]);
};
