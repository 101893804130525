import { AxiosRequestConfig } from "axios";
import axios from "..";
import { UsedAPI } from "../config";
import CookieMonster from "../CookieMonster";

export function showProfileImage() {
  let url = `${UsedAPI}/user/picture/show`;
  let config: AxiosRequestConfig = {
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  };

  return axios.get<any>(url, config);
  // convertToEncodedURL
}
