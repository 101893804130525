/**
 * @todo clear this ListOfRoutes from rapidsense routes and add new Puspenerbal paths
 */
export const ListOfRoutes = {
  loginpage: "/login",
  /**
   *  @deprecated clear this and its respective pages
   */
  dashboard: "/dashboard",
  /**
   *  @deprecated clear this and its respective pages
   */
  management: "/management",
  report: "/report",
  profile: "/profile",
} as const;

export class BaseRoutes {
  constructor(public baseURL: string) {}

  get login(): string {
    return `/login`;
  }

  get home(): string {
    return `/`;
  }
  get dashboard(): string {
    return `/dashboard`;
  }
  get report(): string {
    return `/report`;
  }

  /**
   * @todo profile should only be in sisfopers
   */
  get profile(): string {
    return `${this.baseURL}/profile`;
  }
}

export class NECRoutes extends BaseRoutes {
  constructor() {
    super("");
  }
}

export class SisfopersRoutes extends BaseRoutes {
  constructor() {
    super("/sisfo-pers");
  }
}

export class SisfologRoutes extends BaseRoutes {
  constructor() {
    super("/sisfo-log");
  }
}

export class SisfoOpsRoutes extends BaseRoutes {
  constructor() {
    super("/sisfo-ops");
  }
}

export const EnergyMeterRoutes = {
  /**
   * Energy meter dashboard
   */
  energyMeter: `${ListOfRoutes.dashboard}/energymeter`,
  /**
   * Energy meter dashboard devices. consist of detail data of each energy meter devices
   */
  energyMeterDetail: `${ListOfRoutes.dashboard}/energymeter/devices`,
  energyMeterDetailWithId: `${ListOfRoutes.dashboard}/energymeter/devices/:deviceId`,
  energyMeterDeviceList: `${ListOfRoutes.dashboard}/energymeter/devices`,
} as const;

/**
 *  @deprecated clear this and its respective pages
 */
export const ManagementRoutes = {
  activepenerbal: `${ListOfRoutes.management}/active/penerbal`,
} as const;

/**
 *  @deprecated clear this and its respective pages
 */
