import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BackendDataShape, UsedAPI } from "../../../api/config";
import CookieMonster from "../../../api/CookieMonster";
import { COLORS } from "../../../assets/theme";
import { convertToEncodedURL } from "../../../assets/usefulFunctions";
import { APOCWaterLevelSinarmas, APOCWaterlevelSinarmasReqObject } from "../../../types/endpoints/waterlevel";

export const waterlevelDashboardStoreAPI = createApi({
    reducerPath: "waterlevelStoreAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${UsedAPI}/dashboard`,
        prepareHeaders: (headers) => {
            let token = CookieMonster.loadCookie("jwt");
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["WaterlevelPocSinarmasList"],
    endpoints: (builder) => {
        return {
            getPocWaterlevelDashboard: builder.query<APOCWaterLevelSinarmas, APOCWaterlevelSinarmasReqObject>({
                query: (obj) => {
                    let params = "";
                    if (obj) {
                        params = convertToEncodedURL(obj);
                        params = params.length ? "?" + params : "";
                    }
                    const url = "/waterlevel/poc" + params;
                    return {
                        url,
                        method: "GET",
                    };
                },
                providesTags: ["WaterlevelPocSinarmasList"],
                transformResponse: (response: BackendDataShape<APOCWaterLevelSinarmas>) => {
                    const colorMaps = [COLORS.blue_1, COLORS.green_1, COLORS.red_1, COLORS.yellow];
                    const { locationSummary, ...rest } = response.data;
                    const locationSummaryWithColor = locationSummary.map((item, index) => {
                        return {
                            ...item,
                            color: colorMaps[index % colorMaps.length],
                        }

                    })
                    return { locationSummary: locationSummaryWithColor, ...rest };
                },
            }),
        }
    }
})

export const {
    useGetPocWaterlevelDashboardQuery,
    util: { resetApiState: resetDashboardWaterlevelPocStoreAPI },
} = waterlevelDashboardStoreAPI;