import React from "react";
import { APermissionList } from "../../api/moduleAPI";
import {
  ADashboardPermission,
  AManagementPermission,
  AOtherPermission,
} from "../../api/permissionAPI";
import { useGetCompaniesQuery } from "../../store/companyStore/companyStoreAPI";

type Props = {
  args: {
    id: number;
    name?: string;
  };
  options?: {
    skip?: boolean;
  };
};

const useGetCompanyPermissions = ({ args, options }: Props) => {
  const { data, isLoading, isFetching } = useGetCompaniesQuery(args, options);

  const loading = isLoading || isFetching;
  const defaultPermissions: APermissionList = {
    dashboard: [],
    management: [],
    other: [],
  };

  return React.useMemo(() => {
    let company = data ? data[0] : undefined;
    const dashboardSet = new Set<keyof ADashboardPermission>();
    const managementSet = new Set<keyof AManagementPermission>();
    const otherSet = new Set<keyof AOtherPermission>();

    if (!company)
      return {
        permission: defaultPermissions,
        isLoading: loading,
        dashboardSet,
        managementSet,
        otherSet,
      };

    const { subscribes } = company;

    subscribes.forEach(({ permissions: { dashboard, management, other } }) => {
      dashboard.forEach((val) => {
        dashboardSet.add(val);
      });
      management.forEach((val) => {
        managementSet.add(val);
      });
      other.forEach((val) => {
        otherSet.add(val);
      });
    });

    const permission: APermissionList = Object.assign({}, defaultPermissions);

    dashboardSet.forEach((val1) => {
      permission.dashboard.push(val1);
    });

    managementSet.forEach((val1) => {
      permission.management.push(val1);
    });

    otherSet.forEach((val1) => {
      permission.other.push(val1);
    });

    return {
      permission,
      isLoading: loading,
      dashboardSet,
      managementSet,
      otherSet,
    };
  }, [data, loading]);
};

export default useGetCompanyPermissions;
