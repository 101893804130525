import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "..";
import CookieMonster from "../../api/CookieMonster";
import {
  letUserLogin,
  LoginObj,
  UserDataWOToken,
} from "../../api/credentialsAPI";
import { ListOfRoutes } from "../../config/nav";

import { removeUserData } from "../../store/userStore";

import { convertToEncodedURL } from "../../assets/usefulFunctions";
import { BackendDataShape, CredsLinks } from "../../api/config";

import axios from "../../api";

export const useClearAllCache = () => {

  return () => {
    // Tambahkan secara abjad
  };
};

export const useLogout = () => {
  const clearAllCache = useClearAllCache();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return () => {
    CookieMonster.removeAllTheseCookies([
      "usertype",
      "usertypeId",
      "jwt",
      "companyId",
      "username",
    ]);
    clearAllCache();
    dispatch(removeUserData());
    navigate(ListOfRoutes.loginpage);
    localStorage.clear();
  };
};

export interface GetUserDataReqObj {
  id?: number;
  username?: string;
  email?: string;
}

export function getUser(obj: GetUserDataReqObj) {
  let params = "";
  if (obj) {
    params = convertToEncodedURL(obj);
    params = params.length ? "?" + params : "";
  }

  const url = `${CredsLinks.user(params)}`;
  console.log({ urlGet: url });

  return axios.get<BackendDataShape<UserDataWOToken[]>>(url, {
    headers: {
      Authorization: `Bearer ${CookieMonster.loadCookie("jwt")}`,
    },
  });
}

export const useLogin = () => {
  return async (obj?: LoginObj) => {
    const {
      data: { data },
    } = await letUserLogin(obj);
    const { jwt, exp, iat, ...rest } = data;
    CookieMonster.saveAllTheseCookies(
      {
        jwt,
        usertype: rest.usertypeName,
        companyId: rest.companyId,
        username: rest.username,
        usertypeId: rest.usertypeId,
      },
      { expires: new Date(exp * 1000) },
    );
    return rest;
  };
};
